import Image from "next/image";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { formatImageUrl } from "../../../helper/helper";

const InvigoratedDetails = (props: any) => {
  return (
    <section className="invigorated_details_section">
      <div className="collection_container">
        <h2>{props?.data?.header}</h2>
        <div className="invigorated_details_card_section">
          <Row className="mobile_gap">
            {props?.data?.items?.length > 0 &&
              props?.data?.items?.map((item: any, index: any) => {
                let icon_Image: any = formatImageUrl(
                  item?.image?.data?.attributes?.url
                );
                return (
                  <Col sm={12} md={12} lg={4} key={index} className="column_cards_section">
                    <div className="invigorated_card">
                      <div className="top_dots">
                        <div className="bottom_dots">
                          <div className="invigorated_img_div">
                            {item?.image?.data && (
                              <img
                                src={icon_Image}
                                alt={`barel icon`}
                              // width={102}
                              // height={102}
                              />
                            )}
                          </div>
                          <div className="invigorated_details">
                            <h3>{item?.title}</h3>
                            <p>{item?.description}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                );
              })}
          </Row>
        </div>
      </div>
    </section>
  );
};

export default InvigoratedDetails;
