import Image from "next/image";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { formatImageUrl } from "../../../helper/helper";
import BannerVideo from "../common/video";

const OctaveDetailsSection = (props: any) => {
  const [textManage, setTextManage]: any = useState(true);
  let caskImage: any = formatImageUrl(
    props?.data?.media?.data?.attributes?.url
  );
  let bottle_Image: any = formatImageUrl(
    props?.data?.bottle_image?.data?.attributes?.url
  );

  return (
    <>
      <div className="for_desktop octave_banner">
        <BannerVideo srcURL={props?.data?.video_link} data={"content"} />
      </div>
      <section className="cask_collection_details">
        <div className="collection_container for_mobile">
          <h4>{props?.data?.sub_title}</h4>
          {props?.data?.media?.data && (
            <img
              src={props?.data?.media?.data?.attributes?.url}
              alt={`black bull logo`}
              width={336}
              height={679}
              className="black_bull_collection_logo"
            />
          )}

          <Row>
            <Col sm={12} md={12} lg={6}>
              <div className="cask_details for_mobile">
                <div>
                  <div className={`inner_div ${textManage ? "short_text" : "long_text"}`}>
                    <h2 className="for_desktop">{props?.data?.sub_title}</h2>
                    <p>{props?.data?.content1}</p>
                    <p>{props?.data?.content2}</p>
                    <h3 className="desktop-text">{props?.data?.content3}</h3>
                  </div>
                  <span className="read_more_cask_details" onClick={() => setTextManage(!textManage)}>
                    Read {textManage ? "More" : "Less"}
                  </span>
                </div>
                <div className="cask_mobile_img">
                  {props?.data?.bottle_image?.data && (
                    <img
                      src={bottle_Image}
                      alt={`octave logo`}
                      width={336}
                      height={679}
                    />
                  )}
                </div>
              </div>
            </Col>
            <Col sm={12} md={12} lg={6}>
              <div className="cask_collection_detail_img">
                <h3 className="mobile-text">{props?.data?.content3}</h3>
              </div>
            </Col>
          </Row>
        </div>

        <div className="collection_container for_desktop">
          <Row>
            <Col sm={12} md={12} lg={12}>
              <div className="cask_details octave_cask_details">
                <div className="inner_div">
                  <h2>{props?.data?.sub_title}</h2>
                  {props?.data?.media?.data && (
                    <img
                      src={props?.data?.media?.data?.attributes?.url}
                      alt={`black bull logo`}
                      width={336}
                      height={679}
                      className="black_bull_collection_logo"
                    />
                  )}
                  <p>{props?.data?.content1}</p>
                  <p>{props?.data?.content2}</p>
                  <h3>{props?.data?.content3}</h3>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <div className="for_mobile octave_banner_bottom">
        <BannerVideo srcURL={props?.data?.video_link} data={"content"} />
      </div>
    </>
  );
};

export default OctaveDetailsSection;
