import React, { useState } from "react";
import { Modal } from "react-bootstrap";

const ThanksCaskModal = (props: any) => {
  return (
    <Modal show={props.openModal} centered className="thanks_for_cask_register_modal" aria-labelledby="ModalHeader">
      <div className="thanks_modal_inner_div">
        <div className="close_modal_icon" onClick={() => props.setOpenModal(!props.openModal)}>
          <img src="/new-octave-collection/close-modal-icon.png" alt="close icon" />
        </div>

        <div className="tank_text_div">
          <h2 className="mb-2">Thanks</h2>
          <h2>Your data has been recorded. We'll get back to you shortly.</h2>
        </div>
      </div>
    </Modal>
  );
};

export default ThanksCaskModal;
