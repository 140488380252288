"use client";
import MainLayout from "@/components/layout/mainLayout";
import { useEffect, useState } from "react";
import BuyNowCart from "@/components/common/BuyNowCart";
import SignupForFree from "@/components/landingpage/SignupForFree";
import CaskDetails from "@/components/newoctavecollection/CaskDetails";
import FeaturedBanner from "@/components/newoctavecollection/FeaturedBanner";
import InvigoratedDetails from "@/components/newoctavecollection/InvigoratedDetails";
import NewCollectionBanner from "@/components/newoctavecollection/NewCollectionBanner";
import TheJourneyDetails from "@/components/newoctavecollection/TheJourneyDetails";
import TheWhiskyCollection from "@/components/newoctavecollection/TheWhiskyCollection";
import WhiskyDetailsCard from "@/components/newoctavecollection/WhiskyDetailsCard";
import { getCheckoutLineItems, setRemoveLineItemInCart, setUpdateQuantityInCart } from "@/util/utils";
import OctaveDetailsSection from "@/components/newoctavecollection/OctaveDetailsSection";
import BannerVideo from "@/components/common/video";
import ScoMeta from "@/components/scometa";
import OwnACaskModal from "@/components/newoctavecollection/OwnACaskModal";
import ThanksCaskModal from "@/components/newoctavecollection/ThanksCaskModal";

const NewOctaveCollection = (props: any) => {
  const { data, meta, countryCode } = props;

  const [octavecollectionData, setOctavecollectionData]: any = useState(null);
  const [checkout, setCheckout] = useState({ checkout: { lineItems: [] } });
  const [openModal, setOpenModal]: any = useState(false);
  const [openOwnCaskModal, setOpenOwnCaskModal]: any = useState(false);
  const [openCaskThanksModal, setOpenCaskThanksModal]: any = useState(false);

  useEffect(() => {
    if (data) {
      setOctavecollectionData(data?.data?.attributes?.blocks);
    }
  }, [data]);
  const renderOctaveCollectionPage = () => {
    return (
      <>
        {octavecollectionData && octavecollectionData.length
          ? octavecollectionData.map((content: any, index: any) => {
              switch (content.__component) {
                case "sections.banner":
                  return (
                    <div key={index}>
                      <NewCollectionBanner data={content} />
                    </div>
                  );

                // case "octave.about-the-collection":
                //   return (
                //     <CaskDetails key={content?.id + index} data={content} />
                //   );

                // case "octave.video-section":
                //   let videosrc = content.video_link;
                //   return (
                //     <div key={index}  className="octave_banner">
                //       <BannerVideo srcURL={videosrc} data={content} />
                //     </div>
                //   );
                case "octave.about-the-collection":
                  return <OctaveDetailsSection key={content?.id + index} data={content} />;

                case "octave.how-collection-made":
                  return <InvigoratedDetails key={content?.id + index} data={content} />;
                case "octave.our-collections":
                  return <TheWhiskyCollection key={content?.id + index} data={content} />;
                case "octave.journey":
                  return <TheJourneyDetails key={content?.id + index} data={content} />;
                // case "octave.journey-whiskey":
                //   return (
                //     <WhiskyDetailsCard
                //       key={content?.id + index}
                //       data={content}
                //     />
                //   );
                // case "octave.highland-park":
                //   return (
                //     <FeaturedBanner key={content?.id + index} data={content} />
                //   );

                default:
                  return null;
              }
            })
          : null}
      </>
    );
  };

  const toggleCartSidebar = () => {
    getCheckoutLineItems(countryCode).then((res) => {
      setCheckout({
        ...res,
      });
    });

    setOpenModal(!openModal);
  };

  useEffect(() => {
    getCheckoutLineItems(countryCode).then((res) => {
      setCheckout({
        ...res,
      });
    });
  }, []);

  const updateQuantityInCart = (lineItemId: any, quantity: any) => {
    const lineItemsToUpdate = [{ id: lineItemId, quantity: parseInt(quantity, 10) }];
    setUpdateQuantityInCart(lineItemsToUpdate, countryCode).then((res) => {
      setCheckout({
        ...res,
      });
    });
  };

  const removeLineItemInCart = (lineItemId: any) => {
    setRemoveLineItemInCart(lineItemId, countryCode).then((res) => {
      setCheckout({
        ...res,
      });
    });
  };

  const toggleOwnCaskModal = () => {
    setOpenOwnCaskModal(!openOwnCaskModal);
  };
  return (
    <>
      <button className="own_octave_btn" onClick={toggleOwnCaskModal}>
        {/* <h2>Own a </h2> */}
        <h3>Create your Own</h3>
      </button>
      <OwnACaskModal openOwnCaskModal={openOwnCaskModal} toggleOwnCaskModal={toggleOwnCaskModal} setOpenCaskThanksModal={setOpenCaskThanksModal} />
      <ThanksCaskModal openModal={openCaskThanksModal} setOpenModal={setOpenCaskThanksModal} />
      <BuyNowCart
        countryCode={countryCode}
        openModal={openModal}
        toggleCartSidebar={toggleCartSidebar}
        checkout={checkout}
        updateQuantityInCart={updateQuantityInCart}
        removeLineItemInCart={removeLineItemInCart}
      />
      <head>
        <ScoMeta meta={meta ? meta : {}} />
      </head>
      <MainLayout checkout={checkout} toggleCartSidebar={toggleCartSidebar}>
        <div className="new-octave-collection">
          {octavecollectionData && renderOctaveCollectionPage()}
          <SignupForFree />
        </div>
      </MainLayout>
    </>
  );
};

export default NewOctaveCollection;
