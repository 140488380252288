import { useRouter } from "next/navigation";
import Image from "next/image";
import React from "react";
import { formatImageUrl } from "../../../helper/helper";
import Button from "../common/CommonButton/Button";

const TheJourneyDetails = (props: any) => {
  const route = useRouter();
  let bgImage: any = formatImageUrl(
    window.innerWidth <= 760
      ? props?.data?.media_mobile?.data?.attributes?.url
      : props?.data?.background?.data?.attributes?.url
  );
  let media: any = formatImageUrl(props?.data?.media?.data?.attributes?.url);

  return (
    <section
      className="the_journey_details_section"
      style={{
        backgroundImage: `url(${bgImage})`,
      }}
    >
      <div className="collection_container">
        <div className="journey_details_div">
          <h2>{props?.data?.title}</h2>
          <p>{props?.data?.description}</p>
          <p>{props?.data?.description_bottom}</p>
          {props?.data?.cta?.link ? (
            <Button
              btnText={props?.data?.cta?.title}
              btnClass={"btn_explore"}
              btnLink={props?.data?.cta?.link}
              linkOpenState={props?.data?.cta?.open_in_new_tab == true ? "_blank" : ""}
            />
          ) : null}
        </div>
      </div>

      {/* <div className="mobile_journey_image onlyMobile">
        {props?.data?.media_mobile?.data && (
          <img src={media} alt={`journey image`} width={1290} height={1034} />
        )}
      </div> */}
    </section>
  );
};

export default TheJourneyDetails;
